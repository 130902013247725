(function($) {

    /**-------NAVBAR SHRINK----------------**/ 
$(window).on("scroll", function(){


    if($(this).scrollTop()  > 90){
        $(".navbar").addClass("navbar-shrink");
        // $(".navbar-brand img").attr("src","http://localhost:81/diamond-wp/wp-content/themes/diamond-wp/assets/build/library/img/logo-3.svg");
    
    }else{
        $(".navbar").removeClass("navbar-shrink");
        // $(".navbar-brand img").attr("src","http://localhost:81/diamond-wp/wp-content/themes/diamond-wp/assets/build/library/img/diamond-logo-transparent.svg");
        
    }
  });


}) (jQuery);